import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Loader from '../../global-module/loader/loader';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../auth/core/Auth';
import { Asset, CategoryBudget, ReleaseStats, Task } from '../core/_models';
import { formatMoney } from '../core/Helpers';
import { ProgressBar } from 'react-bootstrap';
import { fetchAssetsByCategory, fetchCampaingsByCategory, fetchTasksByCategory } from '../core/_requests';
import { AssetCategory } from '../../settings/core/Models';

interface BudgetProps {
    releaseStats?: ReleaseStats
}

interface BudgetCategoryItem {
    name: String,
    budget: number
}
const Budget: React.FC<BudgetProps> = ({ releaseStats = undefined }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandedCampaign, setExpandedCampaign] = useState(false);
    const [expandedTask, setExpandedTask] = useState(false);
    const [expandedAsset, setExpandedAsset] = useState(false);
    const [showModal, setShowModel] = useState(false);
    const [listAsset, setListAsset] = useState<BudgetCategoryItem[]>([]);
    const [modalTitle, setModalTitle] = useState<String>("");

    const { release } = useAuth();
    const { currentUser } = useAuth()

    useEffect(() => {

    }, []);
    let campaignCategory: CategoryBudget[] | undefined = releaseStats?.campaignCategoryBudget
    let taskCategory: CategoryBudget[] | undefined = releaseStats?.taskCategoryBudget
    let assetCategory: CategoryBudget[] | undefined = releaseStats?.assetCategoryBudget

    taskCategory && taskCategory.sort(function (a, b) {
        return b.budget - a.budget;
    });
    campaignCategory && campaignCategory.sort(function (a, b) {
        return b.budget - a.budget;
    });

    assetCategory && assetCategory.sort(function (a, b) {
        return b.budget - a.budget;
    });
    const closeModel = () => {
        setShowModel(false);
    }
    const fetchCategoryItem = (c: CategoryBudget) => {
        if (release)
            fetchAssetsByCategory(c.category_id, release).then((res) => {
                // console.log(res.data)
                setModalTitle(c.category_name + ' (' + c.count + ' ' + (c.count !== 1 ? 'Items' : 'Item') + ')')
                setListAsset(res.data.data)
                setShowModel(true);
            }).catch((e) => {
                console.error(e)
            })
    }

    const fetchCampaignItem = (c: CategoryBudget) => {
        if (release)
            fetchCampaingsByCategory(c.category_id, release).then((res) => {
                // console.log(res.data)
                let budgetCategory: BudgetCategoryItem[] = [];
                res.data.data.map((e: any, i: any) => {
                    e.name = e.title
                    budgetCategory = [e, ...budgetCategory];
                })
                setModalTitle(c.category_name + ' (' + c.count + ' ' + (c.count !== 1 ? 'Items' : 'Item') + ')')
                setListAsset(budgetCategory)
                setShowModel(true);
            }).catch((e) => {
                console.error(e)
            })
    }
    const fetchTaskItem = (c: CategoryBudget) => {
        if (release)
            fetchTasksByCategory(c.category_id, release).then((res) => {
                //console.log(res.data)
                let budgetCategory: BudgetCategoryItem[] = [];
                res.data.data.map((e: any, i: any) => {
                    e.name = e.title
                    budgetCategory = [e, ...budgetCategory];
                })
                setModalTitle(c.category_name + ' (' + c.count + ' ' + (c.count !== 1 ? 'Items' : 'Item') + ')')
                setListAsset(budgetCategory)
                setShowModel(true);
            }).catch((e) => {
                console.error(e)
            })
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="item_head">
                        <h3>Budget</h3>

                    </div>
                    <div className='item-body row pb-5'>
                        <div className='col-md-12 mt-4 mb-4'>
                            <div className='fs-1 fw-bolder'>{formatMoney(releaseStats?.totalBudget ?? 0)}</div>
                            <div className='fw-bold fs-5 text-gray-400'>Total Marketing Budget</div>
                        </div>
                        <div className='col-md-4 '>
                            <div className='border border-dashed border-radius-5 p-3'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='fs-2 fw-bolder'>{formatMoney(releaseStats?.campaignBudget ?? 0)}</div>
                                        <div className='fw-bold fs-6 text-gray-400'> Campaign Budget ({releaseStats?.campaignBudgetCount} Total {releaseStats?.campaignBudgetCount != 1 ? 'Items' : 'Item'})</div>

                                    </div>

                                </div>
                                <div className="separator my-3"></div>

                                {
                                    campaignCategory && (!expandedCampaign ? campaignCategory.slice(0, 3) : campaignCategory).map((e, i) => (

                                        <>
                                            <div className='pg d-flex align-items-end '>
                                                <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                                    <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                                        <span className='pg-left clickable' onClick={() => { fetchCampaignItem(e) }}>{e.category_name} (<span className="underline">{e.count} {e.count != 1 ? 'Items' : 'Item'}</span>)</span>
                                                        <span className='pg-right'>{formatMoney(Number(e.budget))}</span>
                                                    </div>

                                                    <div className='h-8px mx-3 w-100 bg-opacity-50 rounded'>
                                                        <ProgressBar now={e.percentage} />
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    ))
                                }
                                {campaignCategory && campaignCategory?.length > 3 ? !expandedCampaign ? <div className='show-more mt-3 mb-1' onClick={() => {
                                    setExpandedCampaign(true);
                                }}>Show More <i className="fas  fa-angle-down text-primary"></i></div> : <div className='show-more mt-3 mb-1' onClick={() => {
                                    setExpandedCampaign(false);
                                }}>Show Less <i className="fas  fa-angle-up text-primary"></i></div> : null
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='border border-dashed border-radius-5 p-3'>
                                <div className='row'>

                                    <div className='col-md-12'>
                                        <div className='fs-2 fw-bolder'>{formatMoney(releaseStats?.taskBudget ?? 0)}</div>
                                        <div className='fw-bold fs-6 text-gray-400'>Task Budget ({releaseStats?.taskBudgetCount} Total {releaseStats?.taskBudgetCount != 1 ? 'Items' : 'Item'})</div>


                                    </div>

                                </div>
                                <div className="separator my-3"></div>
                                {
                                    taskCategory && (!expandedTask ? taskCategory.slice(0, 3) : taskCategory).map((e, i) => (

                                        <>
                                            <div className='pg d-flex align-items-end pt-0'>
                                                <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                                    <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                                        <span className='pg-left clickable' onClick={() => { fetchTaskItem(e) }}>{e.category_name} (<span className="underline">{e.count} {e.count != 1 ? 'Items' : 'Item'}</span>)</span>
                                                        <span className='pg-right'>{formatMoney(Number(e.budget))}</span>
                                                    </div>

                                                    <div className='h-8px mx-3 w-100 bg-opacity-50 rounded'>
                                                        <ProgressBar now={e.percentage} />
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    ))
                                }
                                {taskCategory && taskCategory?.length > 3 ? !expandedTask && taskCategory && taskCategory?.length > 3 ? <div className=' show-more mt-3 mb-1' onClick={() => {
                                    setExpandedTask(true);
                                }}>Show More <i className="fas  fa-angle-down text-primary"></i></div> : <div className=' show-more mt-3 mb-1' onClick={() => {
                                    setExpandedTask(false);
                                }}>Show Less <i className="fas  fa-angle-up text-primary"></i></div> : null
                                }
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='border border-dashed border-radius-5 p-3'>
                                <div className='row'>

                                    <div className='col-md-12'>
                                        <div className='fs-2 fw-bolder'>{formatMoney(releaseStats?.assetBuget ?? 0)}</div>
                                        <div className='fw-bold fs-6 text-gray-400'>Assets Budget ({releaseStats?.assetBudgetCount} Total {releaseStats?.assetBudgetCount != 1 ? 'Items' : 'Item'})</div>
                                    </div>
                                </div>
                                <div className="separator my-3"></div>
                                {
                                    assetCategory && (!expandedAsset ? assetCategory.slice(0, 3) : assetCategory).map((e, i) => (

                                        <>
                                            <div className='pg d-flex align-items-end pt-0'>
                                                <div className='d-flex align-items-center flex-column mt-3 w-100'>
                                                    <div className='d-flex justify-content-between  w-100 mt-auto mb-2'>
                                                        <span className='pg-left clickable' onClick={() => { fetchCategoryItem(e) }}>{e.category_name} (<span className="underline">{e.count} {e.count != 1 ? 'Items' : 'Item'}</span>)</span>
                                                        <span className='pg-right'>{formatMoney(Number(e.budget))}</span>
                                                    </div>

                                                    <div className='h-8px mx-3 w-100 bg-opacity-50 rounded'>
                                                        <ProgressBar now={e.percentage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                                {assetCategory && assetCategory?.length > 3 ? !expandedAsset ? <div className=' show-more mt-3 mb-1' onClick={() => {
                                    setExpandedAsset(true);
                                }}>Show More <i className="fas  fa-angle-down text-primary"></i></div> : <div className=' show-more mt-3 mb-1' onClick={() => {
                                    setExpandedAsset(false);
                                }}>Show Less <i className="fas  fa-angle-up text-primary"></i></div> : null
                                }
                            </div>
                        </div>
                    </div>
                    <Modal show={showModal} onHide={closeModel}>
                        <div className="custom_modal">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {listAsset.map((e, i) => (
                                    <>
                                        <div className='d-flex justify-content-between  w-100 mt-auto mb-2 pg'>
                                            <span className='pg-left'>{e.name}</span>
                                            <span className='pg-right'>{formatMoney(Number(e.budget))}</span>
                                        </div>
                                        {i < listAsset.length - 1 ?
                                            <div className="separator my-3"></div> : null}
                                    </>
                                ))}
                            </Modal.Body>
                        </div>
                    </Modal>
                </>
            )}

        </>
    );
}

export default Budget;