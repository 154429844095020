import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Load services
import instance from '../../../../../services/axiosConfig';
import { BlobOptions } from 'buffer';
import { useAuth } from '../../../../auth/core/Auth';
import { useLocation } from 'react-router-dom';
import { AddProps, CampaignType, Task, TaskCategory, Taskstatus, initialData } from '../../../core/_models';
import { getUser } from '../../../core/_requests';
import { useCommonContext } from '../../../../../services/CommonContext';
import { title } from 'process';
import ReactQuill from 'react-quill';
import { getAssetUrl } from '../../../core/Helpers';


interface AddTaskProps {
    initalData?: AddProps
    dialogAction: any,
    editId: any,
    editData: any,
    viewMode?: boolean,
    onChange(arg0: boolean): any,
    onDataChange(data: AddProps, openAddTask?: boolean, openAddCampaign?: boolean): any
}
const AddTask: React.FC<AddTaskProps> = ({ initalData, dialogAction, editId, editData, onChange, onDataChange, viewMode = false }) => {

    //const { dialogAction, editId, editData, onChange } = props;
    const { budgetUpdated, setBudgetUpdated, campaignUpdated, setCampaignUpdated } = useCommonContext()
    const [show, setShowTask] = useState(dialogAction);
    const [isLoading, setIsLoading] = useState(false);
    const [listCampaignType, setCampaignType] = useState<CampaignType[]>([])
    const currentReleasesId = useLocation().pathname.split('/').pop()!;

    const [listTaskstatus, setTaskStatus] = useState<Taskstatus[]>([]);
    const [listTaskCategory, setTaskCategory] = useState<TaskCategory[]>([]);
    const startDateRef = useRef<HTMLInputElement>(null)
    const [resultText, setResultText] = useState('')
    const [file, setFile] = useState<File | undefined>()

    const handleClose = () => {
        setShowTask(false);
        onChange(false)
    };


    useEffect(() => {
        if (!viewMode) {
            fetchTaskCategory();
            // fetchTaskStatus();
            fetchAllUser();
            fetchCampaignType();
        } else {
            if (editData.campaign_type) {
                setCampaignType([editData.campaign_type])
            }
            if (editData.task_category) {
                setTaskCategory([editData.task_category]);
            }
            if (editData.user) {
                setAllUser([editData.user]);
            }

        }
        if (editId != 0) {
            handleEdit(editData);
        }
    }, []);

    useEffect(() => {
        if (resultText != '')
            setFormData({ ...formData, ['result']: resultText })
    }, [resultText])

    const fetchTaskCategory = async () => {
        const response = await instance.get('/task-category');
        setTaskCategory(response.data?.data);
    };

    const fetchCampaignType = async () => {
        try {
            const response = await instance.get('/campaign-type')
            setCampaignType(response.data?.data)
        } catch (error) { }
    }
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files?.[0])
    }

    // const fetchTaskStatus = async () => {
    //     try {

    //         const response = await instance.get('/task-status');
    //         setTaskStatus(response.data?.data);

    //     } catch (error) {

    //     }
    // };

    // Fetch User Data
    const [listAllUser, setAllUser] = useState<any[]>([]);

    const fetchAllUser = async () => {
        const data = await getUser('');
        setAllUser(data);
    };
    const onIsFixedDateChanged = (e: boolean) => {

        setFormData({ ...formData, ['is_fixed_date']: e });
    }

    // Start form


    const [formData, setFormData] = useState<any>({
        id: undefined,
        title: initalData?.name || '',
        user_id: 0,
        start_week: initalData?.startWeek || '-16',
        start_date: initalData?.startDate || '',
        budget: initalData?.budget || "",
        is_fixed_date: initalData?.startDateIsFixedDate || false,
        // end_date: '',
        tags: '',
        task_status_id: 1,
        description: '',
        release_id: currentReleasesId,
        campaign_id: null,
        type: 'task'
    });

    const [errors, setErrors] = useState({
        title: '',
        task_category_id: '',
        user_id: '',
        start_week: '',
        start_date: '',
        budget: '',
        // start_date: '',
        // end_date: '',
        tags: '',
        task_status_id: '',
        description: '',
        release_id: '',
        campaign_type_id: '',
        end_week: '',
        end_date: '',

    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (e.target.name == "budget") {
            if (e.target.value == "")
                setFormData({ ...formData, [e.target.name]: "" });
            else
                setFormData({ ...formData, [e.target.name]: Number(e.target.value) });
        } else
            setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.title) {
            errors.title = 'Task Name is required.';
        }

        if (data.type == "task") {
            if (!data.task_category_id) {
                errors.task_category_id = 'Category is required.';
            }
            if (!data.is_fixed_date && !(parseInt(data.start_week) >= -16 && parseInt(data.start_week) <= 16)) {
                errors.start_week = 'Week is invalid.';
            }
            if (data.is_fixed_date && !data.start_date) {
                errors.start_date = 'Date is required.';
            }
        } else {
            if (!data.campaign_type_id) {
                errors.campaign_type_id = 'Category is required.';
            }

            if (data.is_fixed_date && !data.start_date) {
                errors.start_date = 'Start date is required.'
            }

            if (
                data.is_fixed_date &&
                data.end_date &&
                new Date(data.end_date) < new Date(data.start_date)
            ) {
                errors.end_date = 'End date should be later than or equal to  start date'
            }
            //        console.log(data.start_week);
            if (!data.is_fixed_date && data.start_week === '') {
                errors.start_week = 'Start week is required.'
            }

            if (!data.is_fixed_date && data.end_week && Number(data.end_week) < Number(data.start_week)) {
                errors.end_week = 'End week should be later than or equal to start week'
            }
        }


        // if (isNaN(data.budget)) {
        //     errors.budget = 'Budget is required.';
        // }

        if (!data.user_id) {
            errors.user_id = 'Assignee is required.';
        }



        // if (!data.start_date) {
        //     errors.start_date = 'Start Date is required.';
        // }

        // if (!data.end_date) {
        //     errors.end_date = 'End Date is required.';
        // }

        if (!data.task_status_id) {
            errors.task_status_id = 'Status required.';
        }



        return Object.keys(errors).length ? errors : null;
    };

    const handleEdit = async (editData: Task) => {
        console.log(editData);
        var editFormobj: Task = {
            id: undefined,
            title: editData?.title,
            task_category_id: editData?.task_category_id,
            user_id: editData?.user_id,
            budget: editData?.budget,
            //start_date: editData?.start_date,
            start_date: editData?.start_date,
            end_date: editData?.end_date,

            start_week: editData?.start_week,
            end_week: editData?.end_week,


            tags: editData?.tags,
            task_status_id: editData?.task_status_id,
            description: editData?.description,
            release_id: editData?.release_id,
            is_fixed_date: editData?.is_fixed_date,
            type: editData?.type,
            campaign_type_id: editData?.campaign_type_id,
            campaign_id: editData?.campaign_id,
            asset_url: editData?.asset_url
        }
        setFormData(editFormobj);
        setResultText(editData.result ?? '')
    }

    const handleSubmit = async () => {

        // Validate form data

        const validationErrors = validateForm(formData);
        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }

        if (editId != 0) {

            // Edit data
            setIsLoading(true);
            try {

                if (file) formData.asset = file
                if (!formData.budget) formData.budget = 0;
                const response = await instance.post('/task/' + editId + '?_method=PUT', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                toast.success(response?.data?.message);
                setShowTask(false);
                onChange(false);
                setIsLoading(false);
                setBudgetUpdated(!budgetUpdated);
                setCampaignUpdated(!campaignUpdated);
                onDataChange(initialData)

            } catch (error) {

                toast.error('something went wrong please try again');
                setTimeout(function () {
                    setIsLoading(false);
                }, 2000);

            }
        }
        else {
            // Add data
            setIsLoading(true);

            try {
                if (file) formData.asset = file
                if (!formData.budget) formData.budget = 0;

                const response = await instance.post('/task', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                toast.success(response?.data?.message);
                //setTimeout(function () {
                setShowTask(false);
                onChange(false);
                setIsLoading(false);
                setBudgetUpdated(!budgetUpdated);
                setCampaignUpdated(!campaignUpdated);
                onDataChange(initialData)

            } catch (error) {
                console.log(error);
                toast.error('something went wrong please try again');
                setTimeout(function () {
                    setShowTask(false);
                    onChange(false);
                    setIsLoading(false);
                }, 2000);
            }
        }

    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (<></>)}

            <Modal show={show} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {editId != 0 ? viewMode ? 'Item' : 'Update Item' : 'Add New Item'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            {/* <div className='col-md-12'>
                                <button className='btn btn-info' disabled>Task</button>
                                <button className='btn btn-info' onClick={() => {
                                    onDataChange({
                                        name: formData.title,
                                        budget: formData.budget,
                                        startDate: formData.start_date,
                                        startWeek: formData.start_week,
                                        startDateIsFixedDate: formData.is_fixed_date
                                    }, false, true)
                                }}>Campaign</button>
                            </div> */}
                            <div className='col-md-12'>
                                <label className='labels'>Type</label>
                            </div>
                            <div className='col-md-6'>
                                {/* begin::Radio */}

                                <div className='form-group form-check form-check-custom form-check-solid'>

                                    {/* begin::Input */}
                                    <input
                                        className='form-check-input me-3'
                                        onChange={handleChange}
                                        name='type'
                                        type='radio'
                                        value='task'
                                        id='kt_modal_update_role_option_0'
                                        disabled={viewMode ? true : false}
                                        checked={formData.type == 'task'}
                                    />

                                    {/* end::Input */}
                                    {/* begin::Label */}
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                                        <div className='fw-bolder text-gray-800'>Task</div>
                                    </label>
                                    {/* end::Label */}
                                </div>
                                {/* end::Radio */}
                            </div>

                            <div className='col-md-6'>
                                {/* begin::Radio */}
                                <div className=' form-group form-check form-check-custom form-check-solid'>
                                    {/* begin::Input */}
                                    <input
                                        className='form-check-input me-3'
                                        onChange={handleChange}
                                        name='type'
                                        type='radio'
                                        value='campaign'
                                        id='kt_modal_update_role_option_1'
                                        disabled={viewMode ? true : false}
                                        checked={formData.type == 'campaign'}
                                    />
                                    {/* end::Input */}
                                    {/* begin::Label */}
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                                        <div className='fw-bolder text-gray-800'>Campaign</div>

                                    </label>
                                    {/* end::Label */}
                                </div>
                                {/* end::Radio */}
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>{formData.type == 'campaign' ? 'Campaign' : 'Task'} Name</label>
                                    <input className='form-control' placeholder={formData.type == 'campaign' ? 'Campaign Name' : 'Task Name'} type="text" id="title" name="title" value={formData.title} onChange={handleChange} disabled={viewMode ? true : false} />
                                    {errors.title && formData.title === '' && <div className='error-form'>{errors.title}</div>}
                                </div>

                            </div>
                            {
                                formData.type == 'campaign' ?
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className='labels'>Category</label>
                                            <select
                                                className='form-control'
                                                name='campaign_type_id'
                                                value={formData.campaign_type_id ? formData.campaign_type_id : ''}
                                                onChange={handleChange}
                                                disabled={viewMode ? true : false}
                                            >
                                                <option value='' defaultValue={''}>
                                                    Select Category
                                                </option>
                                                {listCampaignType.map((campaignType) => (
                                                    <option key={campaignType.id} value={campaignType.id}>
                                                        {campaignType.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.campaign_type_id && (
                                                <div className='error-form'>{errors.campaign_type_id}</div>
                                            )}
                                        </div>
                                    </div>
                                    :

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='labels'>Category</label>
                                            <select className='form-control' name='task_category_id'
                                                value={formData.task_category_id ? formData.task_category_id : ''}
                                                onChange={handleChange}
                                                disabled={viewMode ? true : false}>
                                                <option value='' defaultValue={''}>Select Category</option>
                                                {listTaskCategory.map((categoryData) => (
                                                    <option key={categoryData.id} value={categoryData.id}>{categoryData.name}</option>
                                                ))}
                                            </select>
                                            {errors.task_category_id && <div className='error-form'>{errors.task_category_id}</div>}
                                        </div>
                                    </div>
                            }
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='labels'>Assignee</label>
                                    <select className='form-control' name='user_id' value={formData.user_id} onChange={handleChange} disabled={viewMode ? true : false}>
                                        <option value='' >Select Assignee</option>
                                        {listAllUser.map((userData) => (
                                            <option key={userData.id} value={userData.id}>{userData.name}</option>
                                        ))}
                                    </select>
                                    {errors.user_id && <div className='error-form'>{errors.user_id}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Add Budget</label>
                                    <input className='form-control' placeholder='Add Budget' type="number" id="budget" name="budget" value={formData.budget} onWheel={(e) => e.currentTarget.blur()} onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} onChange={handleChange} disabled={viewMode ? true : false} />
                                    {errors.title && isNaN(formData.budget) && <div className='error-form'>{errors.budget}</div>}
                                </div>
                            </div>
                            {formData.type == "campaign" ?
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label className='labels'>Image</label>
                                        {formData.asset_url && file == undefined ? (
                                            <img src={getAssetUrl(formData.asset_url)}></img>
                                        ) : null}
                                        <input
                                            className='form-control'
                                            placeholder='Choose File'
                                            type='file'
                                            id='asset'
                                            name='asset'
                                            disabled={viewMode ? true : false}
                                            onChange={handleFileChange}
                                        />

                                    </div>
                                </div> : null
                            }
                            <div className='col-md-12'>
                                <label className='labels'>Start Date</label>
                            </div>
                            <div className='col-md-6'>
                                {/* begin::Radio */}

                                <div className='form-group form-check form-check-custom form-check-solid'>

                                    {/* begin::Input */}
                                    <input
                                        className='form-check-input me-3'
                                        onChange={() => onIsFixedDateChanged(false)}
                                        name='role'
                                        type='radio'
                                        value='Administrator'
                                        id='kt_modal_update_role_option_0'
                                        disabled={viewMode ? true : false}
                                        checked={formData.is_fixed_date == false}
                                    />

                                    {/* end::Input */}
                                    {/* begin::Label */}
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                                        <div className='fw-bolder text-gray-800'>Week</div>

                                    </label>
                                    {/* end::Label */}
                                </div>
                                {/* end::Radio */}
                            </div>

                            <div className='col-md-6'>
                                {/* begin::Radio */}
                                <div className=' form-group form-check form-check-custom form-check-solid'>
                                    {/* begin::Input */}
                                    <input
                                        className='form-check-input me-3'
                                        onChange={() => onIsFixedDateChanged(true)}
                                        name='role'
                                        type='radio'
                                        value='Developer'
                                        id='kt_modal_update_role_option_1'
                                        checked={formData.is_fixed_date == true}
                                        disabled={viewMode ? true : false} />
                                    {/* end::Input */}
                                    {/* begin::Label */}
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                                        <div className='fw-bolder text-gray-800'>Fixed</div>

                                    </label>
                                    {/* end::Label */}
                                </div>
                                {/* end::Radio */}
                            </div>

                            {formData.type == "task" ? <>
                                {formData.is_fixed_date == false ?
                                    <div className="col-md-12" >
                                        <div className="form-group">
                                            <label className='labels'>Select Week</label>
                                            <select className='form-control' name='start_week' value={formData.start_week} onChange={handleChange} >
                                                <option value='' defaultValue={''}>Select a Week</option>

                                                <option value={-16}>Week -16</option>
                                                <option value={-15}>Week -15</option>
                                                <option value={-14}>Week -14</option>
                                                <option value={-13}>Week -13</option>
                                                <option value={-12}>Week -12</option>
                                                <option value={-11}>Week -11</option>
                                                <option value={-10}>Week -10</option>
                                                <option value={-9}>Week -9</option>
                                                <option value={-8}>Week -8</option>
                                                <option value={-7}>Week -7</option>
                                                <option value={-6}>Week -6</option>
                                                <option value={-5}>Week -5</option>
                                                <option value={-4}>Week -4</option>
                                                <option value={-3}>Week -3</option>
                                                <option value={-2}>Week -2</option>
                                                <option value={-1}>Week -1</option>
                                                <option value={0}>Week 0</option>
                                                <option value={1}>Week 1</option>
                                                <option value={2}>Week 2</option>
                                                <option value={3}>Week 3</option>
                                                <option value={4}>Week 4</option>
                                                <option value={5}>Week 5</option>
                                                <option value={6}>Week 6</option>
                                                <option value={7}>Week 7</option>
                                                <option value={8}>Week 8</option>
                                                <option value={9}>Week 9</option>
                                                <option value={10}>Week 10</option>
                                                <option value={11}>Week 11</option>
                                                <option value={12}>Week 12</option>
                                                <option value={13}>Week 13</option>
                                                <option value={14}>Week 14</option>
                                                <option value={15}>Week 15</option>
                                                <option value={16}>Week 16</option>
                                            </select>
                                            {errors.start_week && <div className='error-form'>{errors.start_week}</div>}
                                        </div>
                                    </div> :

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className='labels'>Date</label>
                                            <input className='form-control' placeholder='Select a date' type='date' name="start_date" value={formData.start_date} onChange={handleChange} />
                                            {errors.start_date && <div className='error-form'>{errors.start_date}</div>}
                                        </div>
                                    </div>
                                }</> : <>
                                {formData.is_fixed_date ? (
                                    <>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='labels'>Start Date</label>
                                                <input
                                                    disabled={viewMode ? true : false}
                                                    className='form-control'
                                                    placeholder='Select a date'
                                                    name='start_date'
                                                    type='date'
                                                    ref={startDateRef}
                                                    onClick={() => {
                                                        startDateRef.current?.focus()
                                                    }}
                                                    value={formData.start_date}
                                                    onChange={handleChange}
                                                />
                                                {errors.start_date && formData.start_date === '' && (
                                                    <div className='error-form'>{errors.start_date}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='labels'>End Date</label>
                                                <input
                                                    className='form-control'
                                                    placeholder='Select a date'
                                                    name='end_date'
                                                    type='date'
                                                    disabled={viewMode ? true : false}
                                                    value={formData.end_date}
                                                    onChange={handleChange}
                                                />
                                                {errors.end_date &&
                                                    formData.end_date &&
                                                    new Date(formData.end_date) < new Date(formData.start_date!) && (
                                                        <div className='error-form'>{errors.end_date}</div>
                                                    )}
                                            </div>
                                        </div>{' '}
                                    </>
                                ) : (
                                    <>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='labels'>Start Week</label>
                                                <select
                                                    className='form-control'
                                                    name='start_week'
                                                    disabled={viewMode ? true : false}
                                                    value={formData.start_week}
                                                    onChange={handleChange}
                                                >
                                                    <option value='' defaultValue={''}>
                                                        Select a Week
                                                    </option>

                                                    <option value={-16}>Week -16</option>
                                                    <option value={-15}>Week -15</option>
                                                    <option value={-14}>Week -14</option>
                                                    <option value={-13}>Week -13</option>
                                                    <option value={-12}>Week -12</option>
                                                    <option value={-11}>Week -11</option>
                                                    <option value={-10}>Week -10</option>
                                                    <option value={-9}>Week -9</option>
                                                    <option value={-8}>Week -8</option>
                                                    <option value={-7}>Week -7</option>
                                                    <option value={-6}>Week -6</option>
                                                    <option value={-5}>Week -5</option>
                                                    <option value={-4}>Week -4</option>
                                                    <option value={-3}>Week -3</option>
                                                    <option value={-2}>Week -2</option>
                                                    <option value={-1}>Week -1</option>
                                                    <option value={0}>Week 0</option>
                                                    <option value={1}>Week 1</option>
                                                    <option value={2}>Week 2</option>
                                                    <option value={3}>Week 3</option>
                                                    <option value={4}>Week 4</option>
                                                    <option value={5}>Week 5</option>
                                                    <option value={6}>Week 6</option>
                                                    <option value={7}>Week 7</option>
                                                    <option value={8}>Week 8</option>
                                                    <option value={9}>Week 9</option>
                                                    <option value={10}>Week 10</option>
                                                    <option value={11}>Week 11</option>
                                                    <option value={12}>Week 12</option>
                                                    <option value={13}>Week 13</option>
                                                    <option value={14}>Week 14</option>
                                                    <option value={15}>Week 15</option>
                                                    <option value={16}>Week 16</option>
                                                </select>
                                                {errors.start_week && <div className='error-form'>{errors.start_week}</div>}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label className='labels'>End Week</label>
                                                <select
                                                    className='form-control'
                                                    name='end_week'
                                                    disabled={viewMode ? true : false}
                                                    value={formData.end_week}
                                                    onChange={handleChange}
                                                >
                                                    <option value='' defaultValue={''}>
                                                        Select a Week
                                                    </option>
                                                    <option value={-16}>Week -16</option>
                                                    <option value={-15}>Week -15</option>
                                                    <option value={-14}>Week -14</option>
                                                    <option value={-13}>Week -13</option>
                                                    <option value={-12}>Week -12</option>
                                                    <option value={-11}>Week -11</option>
                                                    <option value={-10}>Week -10</option>
                                                    <option value={-9}>Week -9</option>
                                                    <option value={-8}>Week -8</option>
                                                    <option value={-7}>Week -7</option>
                                                    <option value={-6}>Week -6</option>
                                                    <option value={-5}>Week -5</option>
                                                    <option value={-4}>Week -4</option>
                                                    <option value={-3}>Week -3</option>
                                                    <option value={-2}>Week -2</option>
                                                    <option value={-1}>Week -1</option>
                                                    <option value={0}>Week 0</option>
                                                    <option value={1}>Week 1</option>
                                                    <option value={2}>Week 2</option>
                                                    <option value={3}>Week 3</option>
                                                    <option value={4}>Week 4</option>
                                                    <option value={5}>Week 5</option>
                                                    <option value={6}>Week 6</option>
                                                    <option value={7}>Week 7</option>
                                                    <option value={8}>Week 8</option>
                                                    <option value={9}>Week 9</option>
                                                    <option value={10}>Week 10</option>
                                                    <option value={11}>Week 11</option>
                                                    <option value={12}>Week 12</option>
                                                    <option value={13}>Week 13</option>
                                                    <option value={14}>Week 14</option>
                                                    <option value={15}>Week 15</option>
                                                    <option value={16}>Week 16</option>
                                                </select>
                                                {errors.end_week &&
                                                    formData.end_week &&
                                                    Number(formData.end_week) < Number(formData.start_week) && (
                                                        <div className='error-form'>{errors.end_week}</div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>}
                            {formData.type == "campaign" ?
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label className='labels'>Results</label>
                                        <ReactQuill theme='snow' readOnly={viewMode ? true : false} value={resultText} onChange={setResultText} />
                                        {/* <textarea className='form-control' placeholder='Enter Campaign Result' id="result" name="result" value={formData.result} onChange={handleChange} /> */}
                                        {/* {errors.result && formData.result === '' && <div className='error-form'>{errors.result}</div>} */}
                                    </div>
                                </div> : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {!viewMode ? <Button variant="" onClick={handleSubmit} disabled={viewMode ? true : false} className='btn-active'>
                            {editId != 0 ? 'Update' : 'Add'}
                        </Button> : null}
                    </Modal.Footer>
                </div>
            </Modal>

        </>
    )
}


export default AddTask;