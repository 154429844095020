import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../../../global-module/loader/loader';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Load services
import instance from '../../../../../services/axiosConfig';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../auth/core/Auth';
import { Asset } from '../../../core/_models';
import { useCommonContext } from '../../../../../services/CommonContext';


interface AddEditAssetsProps {
    dialogAction: any,
    sendData(data: boolean): any,
    editId?: number,
    editData?: Asset,
    callback(): any;
}

const AddEditAssets = (props: AddEditAssetsProps) => {

    const { dialogAction, sendData, editId, editData, callback } = props;
    const [show, setShowAssets] = useState(dialogAction);
    const [isLoading, setIsLoading] = useState(false);
    const { budgetUpdated, setBudgetUpdated } = useCommonContext()

    let releaseId: string = useLocation().pathname.split('/').pop()!;


    const handleClose = () => {
        setShowAssets(false);
        sendData(false);
    };

    // Fetch the catgeory

    interface Assetscategory {
        id: number;
        name: string;
        created_at: number;
    }
    const [listAssetsCategory, setAssetsCategory] = useState<Assetscategory[]>([]);
    useEffect(() => {
        fetchAssetsCategory();
    }, []);

    const fetchAssetsCategory = async () => {
        try {
            const response = await instance.get('/asset-category');
            setAssetsCategory(response.data?.data);
        } catch (error) {

        }

    };

    // Start form

    const [formData, setFormData] = useState<Asset>({
        name: '',
        asset_category_id: 0,
        budget: 0,
        release_id: releaseId,
        url: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        asset_category_id: '',
        release_id: '',
        url: '',
        budget: ''
    });
    useEffect(() => {
        if (editData) {
            console.log(editData);
            setFormData({
                name: editData.name,
                asset_category_id: editData.asset_category_id,
                release_id: editData.release_id,
                budget: editData.budget,
                url: editData.url
            });
        }
    }, [])



    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        if (!data.name) {
            errors.name = 'Name is required.';
        }

        if (!data.asset_category_id) {
            errors.asset_category_id = 'Category is required.';
        }

        if (!data.release_id) {
            errors.release_id = 'Release type required.';
        }

        if (!data.url) {
            errors.url = 'Url is required.';
        }

        return Object.keys(errors).length ? errors : null;
    };

    const handleSubmit = async () => {

        // Validate form data
        const validationErrors = validateForm(formData);
        if (validationErrors) {
            setErrors(validationErrors);
            return;
        }
        setIsLoading(true);

        if (editData) {
            instance.put('/asset/' + editData.id, formData).then((response) => {
                callback()
                toast.success(response?.data?.message);
                setShowAssets(false);
                sendData(false);
                setIsLoading(false);
                setBudgetUpdated(!budgetUpdated)
            }).catch((error) => {
                toast.error('something went wrong please try again');
                setShowAssets(false);
                sendData(false);
                setIsLoading(false);
            })

        } else {
            // Add data

            try {

                const response = await instance.post('/asset', formData);
                callback()
                setBudgetUpdated(!budgetUpdated)
                toast.success(response?.data?.message);
                setTimeout(function () {
                    setShowAssets(false);
                    sendData(false);
                    setIsLoading(false);
                }, 2000);

            } catch (error) {
                toast.error('something went wrong please try again');
                setTimeout(function () {
                    setShowAssets(false);
                    sendData(false);
                    setIsLoading(false);
                }, 2000);
            }
        }

    };

    return (
        <>

            {isLoading ? (
                <Loader />
            ) : (<></>)}

            <Modal show={show} onHide={handleClose}>
                <div className="custom_modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{editData ? "Update Asset" : "Add Asset"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Asset Name</label>
                                    <input className='form-control' placeholder='Add Asset Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                                    {errors.name && formData.name === '' && <div className='error-form'>{errors.name}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Category</label>
                                    <select className='form-control' name='asset_category_id' value={formData.asset_category_id} onChange={handleChange}>
                                        <option value='' defaultValue={''}>Select Category</option>

                                        {listAssetsCategory.map((categoryData) => (
                                            <option value={categoryData.id}>{categoryData.name}</option>
                                        ))}

                                    </select>
                                    {errors.asset_category_id && formData.asset_category_id === 0 && <div className='error-form'>{errors.asset_category_id}</div>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Budget</label>
                                    <input className='form-control' placeholder='Enter Budget' type="number" id="budget" name="budget" value={formData.budget} onChange={handleChange} />
                                    {errors.name && isNaN(formData.budget) && <div className='error-form'>{errors.budget}</div>}
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className='labels'>Link to Asset
                                    </label>
                                    <input className='form-control' placeholder='Add Link to Asset' type="text" id="url" name="url" value={formData.url} onChange={handleChange} />
                                    {errors.url && formData.url === '' && <div className='error-form'>{errors.url}</div>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="" onClick={handleSubmit} className='btn-active'>
                            {editData ? "Update Asset" : "Add Asset"}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
            <ToastContainer />
        </>
    )

}

export default AddEditAssets;